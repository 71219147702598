<template>
  <div class="about">
    <div class="about__content container">
      <div v-if="homeData" class="about__items">
        <div class="about__items-list">
          <div data-aos="zoom-out-left" class="about__items-list-left">
            <div class="about__items-list-left-img">
              <img :src="$store.state.cdn + homeData?.image1" alt="" />
            </div>
          </div>
          <div
            data-aos-duration="2000"
            data-aos="zoom-out-right"
            class="about__items-list-right"
          >
            <div class="about__items-list-right-top">
              <p class="f-40" v-html="homeData?.title1"></p>
              <p class="f-18" v-html="homeData?.description1"></p>
            </div>
            <div class="about__items-list-right-bottom">
              <span class="f-20">{{ $t("other.ots") }}:</span>
              <div
                class="about__items-list-right-bottom-table"
                v-html="homeData?.table_desktop1"
              ></div>
            </div>
          </div>
        </div>
        <div class="about__items-list">
          <div
            style="z-index: 1"
            data-aos-duration="2000"
            data-aos="zoom-out"
            class="about__items-list-text"
          >
            <span v-html="homeData?.title2"></span>
          </div>
          <div data-aos="zoom-out-left" class="about__items-list-left2">
            <div class="about__items-list-left2-img">
              <img :src="lang === 'kz' ? imgKz : lang === 'ru' ? imgRu : imgKz" alt="" />
            </div>
          </div>
          <div
            data-aos="zoom-out-right"
            data-aos-duration="2000"
            class="about__items-list-right2"
          >
            <div class="about__items-list-right2-text">
              <span class="f-18" v-html="homeData?.desc2_first"></span>
              <!-- Общая площадь экрана: <strong class="f-30">103,21 м²</strong> -->
              <p class="f-18" v-html="homeData?.desc2_second"></p>
              <!-- 1344 px 192 разрешение одного экрана  -->
              <span class="f-18" v-html="homeData?.desc2_third"></span>
              <!-- Режим ротации: <strong class="f-30">24/7</strong> <p class="f-18">Круглосуточно</p> -->
            </div>
            <div
              class="about__items-list-right2-tb"
              v-html="homeData?.table2"
            ></div>
            <!-- <table class="table table-bordered">
              <tbody>
                <tr>
                  <td  class="f-18">Кодек</td>
                  <td>H.264</td>
                </tr>
                <tr>
                  <td  class="f-18">Длительность</td>
                  <td>0:00:15</td>
                </tr>
                <tr>
                  <td  class="f-18">Ширина</td>
                  <td>1344 px</td>
                </tr>
                <tr>
                  <td  class="f-18">Высота</td>
                  <td>192 px</td>
                </tr>
                <tr>
                  <td style="background-color: #F7F7F7;" class="f-18">Соотношение сторон</td>
                  <td>7:1</td>
                </tr>
                <tr>
                  <td style="background-color: #F7F7F7;" class="f-18">Кадровая частота</td>
                  <td>25.00</td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgKz from "@/assets/images/svg/monitor-kz.svg";
import imgRu from "@/assets/images/svg/monitor-ru.svg";
export default {
  props: ['homeData'],
 data() {
  return{
    lang: localStorage.getItem('lang') || 'kz',
    imgRu: imgRu,
    imgKz: imgKz
  }
}

};
</script>

<style></style>
