<template>
 <div>
  <!-- <Header /> -->
  <component :is="componentId">
  <router-view/>
  </component>
  <!-- <Footer /> -->
 </div>
</template>

<script>
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'
import PersonalLayout from "./layout/PersonalLayout.vue";
export default {
  components: {Header,Footer,PersonalLayout},
  computed: {
    componentId() {
      return (this.$route.meta.layout || "base") + "-layout";
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #252328;
  overflow: hidden!important;
}
</style>
