import { createApp } from "vue";
import { createHead } from "@vueuse/head"
const head = createHead()
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "swiper/css";
import "swiper/swiper.min.css";
import "swiper/scss/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import axios from "axios";
import i18n from "@/plugins/i18n";
import VueTheMask from "vue-the-mask";
import VueCookies from "vue-cookies";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronDown,
  faXmark,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang","ru")
}

const lang = localStorage.getItem("lang") || "ru";
axios.defaults.baseURL = `https://admin.supersign.kz/api/`;
// axios.defaults.baseURL = `http://localhost:3333/api/`;

axios.defaults.params = {
  lang: lang,
};
if ($cookies.get("token")) {
  axios.defaults.headers = {
    Authorization: `Bearer ${$cookies.get("token")}`,
  };
}

const options = {
  position: "top-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

library.add(faXmark, faChevronUp, faChevronDown);

createApp(App)
  .use(store)
  .use(router)
  .use(head)
  .use(i18n)
  .use(VueTheMask)
  .use(VueCookies, { expires: "1d" })
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(Toast, options)
  .component("Datepicker", VueDatepickerUi)
  .mount("#app");
