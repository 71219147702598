<template>
  <div class="footer">
    <div class="footer__content">
      <div v-if="footerData" class="footer__items">
        <div class="footer__items-row container">
          <button @click="scrollToTop" class="btn-circle">
            <svg
              width="10"
              height="17"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5L5.70711 1.7071C5.31658 1.31658 4.68342 1.31658 4.29289 1.7071L1 5M5 2L5 16"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </button>
          <div class="footer__items-row-col1">
            <span class="f-16">{{ $t("other.contacts") }}</span>
            <ul>
              <li>
                <span
                  ><svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.0604 3.36021C6.34081 0.0798016 11.6594 0.0798011 14.9398 3.36021C18.2202 6.64061 18.2202 11.9592 14.9398 15.2396L9.0001 21.1793L3.0604 15.2396C-0.220004 11.9592 -0.220004 6.64061 3.0604 3.36021ZM9.0001 11.6999C10.3256 11.6999 11.4001 10.6254 11.4001 9.2999C11.4001 7.97442 10.3256 6.8999 9.0001 6.8999C7.67462 6.8999 6.6001 7.97442 6.6001 9.2999C6.6001 10.6254 7.67462 11.6999 9.0001 11.6999Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                </span>
                <p class="f-14" v-html="footerData.city"></p>
              </li>
              <li>
                <span
                  ><svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 0C1.11929 0 0 1.11929 0 2.5V4.5C0 10.299 4.70101 15 10.5 15H12.5C13.8807 15 15 13.8807 15 12.5V11.118C15 10.5499 14.679 10.0305 14.1708 9.77639L11.7549 8.56843C10.9384 8.1602 9.94971 8.56975 9.66105 9.43573L9.36328 10.329C9.25014 10.6684 8.90197 10.8705 8.55114 10.8003C6.35528 10.3612 4.63885 8.64472 4.19967 6.44886C4.12951 6.09803 4.33156 5.74986 4.67097 5.63672L5.7796 5.26718C6.52319 5.01932 6.95058 4.24075 6.76048 3.48035L6.17444 1.1362C6.0075 0.468446 5.40752 0 4.71922 0H2.5Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                </span>
                <p class="f-14">{{ footerData.phone_number }}</p>
              </li>
              <li>
                <span
                  ><svg
                    width="15"
                    height="13"
                    viewBox="0 0 15 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1.5C0 0.671572 0.671573 0 1.5 0H13.5C14.3284 0 15 0.671573 15 1.5V2.70758L7.50001 6.92632L0 2.70757V1.5Z"
                      fill="#F2F2F2"
                    />
                    <path
                      d="M0 3.85491V11.5C0 12.3284 0.671573 13 1.5 13H13.5C14.3284 13 15 12.3284 15 11.5V3.85493L7.50001 8.07367L0 3.85491Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                </span>
                <p class="f-14">{{ footerData.email }}</p>
              </li>
            </ul>
            <button
              @click="$router.push({ name: 'Live' })"
              class="f-18 btn_blue"
            >
              {{ $t("button.seePanel") }}
            </button>
          </div>
          <div class="footer__items-row-col2">
            <iframe
              :src="footerData.map"
              width="600"
              height="450"
              style="border: 0;filter: grayscale(100%) invert(92%) contrast(83%);"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="footer__items-bottom">
          <div class="container">
            <div class="footer__items-bottom-left">
              <p>{{ footerData.Reserved }}</p>
            </div>
            <div v-if="footerData" class="footer__items-bottom-right">
              <a
                :href="$store.state.cdn + footerData.public_confidential"
                target="_blank"
                ><span
                  ><svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.600098 2.0998C0.600098 1.10569 1.40598 0.299805 2.4001 0.299805H6.52732C7.00468 0.299805 7.46251 0.489444 7.8001 0.827016L10.8729 3.8998C11.2105 4.23737 11.4001 4.69521 11.4001 5.17259V12.8998C11.4001 13.8939 10.5942 14.6998 9.6001 14.6998H2.4001C1.40598 14.6998 0.600098 13.8939 0.600098 12.8998V2.0998ZM2.4001 7.4998C2.4001 7.00275 2.80305 6.5998 3.3001 6.5998H8.7001C9.19717 6.5998 9.6001 7.00275 9.6001 7.4998C9.6001 7.99687 9.19717 8.3998 8.7001 8.3998H3.3001C2.80305 8.3998 2.4001 7.99687 2.4001 7.4998ZM3.3001 10.1998C2.80305 10.1998 2.4001 10.6027 2.4001 11.0998C2.4001 11.5969 2.80305 11.9998 3.3001 11.9998H8.7001C9.19717 11.9998 9.6001 11.5969 9.6001 11.0998C9.6001 10.6027 9.19717 10.1998 8.7001 10.1998H3.3001Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </span>
                <p>{{ $t("other.teerms") }}</p></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    footerData: null,
    lang: "ru",
  }),
  created() {
    this.FETCH_FOOTER();
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
  methods: {
    FETCH_FOOTER() {
      axios
        .get("footer")
        .then((response) => {
          if (response.status === 200) {
            this.footerData = response.data;
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          this.LOADER();
          alert(e.response.data?.message);
        });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
  },
  },
};
</script>

<style></style>
