import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      layout: "main",
    }
  },
  {
    path: '/personal/live',
    name: 'Live',
    meta: {
      layout: "personal",
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/personal/Live.vue')
    }
  }, {
    path: '/personal/report',
    name: 'Report',
    meta: {
      layout: "personal",
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/personal/Report.vue')
    }
  }
  // , {
  //   path: '/tesst',
  //   name: 'Report',
  //   meta: {
  //     layout: "main",
  //   },
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
