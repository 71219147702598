<template>
  <div v-if="homeData" id="consultation" class="consultation">
    <div class="consultation__content container">
      <div class="consultation__items">
        <div class="consultation__items-row">
          <div class="consultation__items-row-col1">
            <div class="cContent">
              <div class="cContent__title">
                <p class="f-20">{{ homeData?.title }}</p>
                <p class="f-14">
                  {{ homeData?.desc }}
                </p>
              </div>
              <form
                onsubmit="return false"
                @submit="SUMBIT()"
                class="cContent__form"
              >
                <div class="basicInput">
                  <div class="showHide">
                    <input
                      :class="{
                        invalid:
                          v$.userData.name.$dirty && v$.userData.name.$invalid,
                      }"
                      v-model="userData.name"
                      type="text"
                      :placeholder="$t('placeholder.name')"
                    />
                    <p
                      v-for="error of v$.userData.name.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </p>
                  </div>
                  <div class="showHide">
                    <input
                      :class="{
                        invalid:
                          v$.userData.phoneNumber.$dirty &&
                          v$.userData.phoneNumber.$invalid,
                      }"
                      v-model="userData.phoneNumber"
                      v-mask="'+7 (###) ###-##-##'"
                      type="tel"
                      :placeholder="$t('placeholder.phone_number')"
                    />
                    <p
                      v-for="error of v$.userData.phoneNumber.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </p>
                  </div>
                  <div class="basicInput">
                    <div class="checkbox inputStyled">
                      <input
                        :class="{
                          invalid:
                            v$.userData.agree.$dirty &&
                            v$.userData.agree.$invalid,
                        }"
                        type="checkbox"
                        id="checkbox"
                        v-model="userData.agree"
                      />
                      <h5 style="margin-top: 0" class="f-12">
                        {{ $t("other.agree1") }}
                        <a :href="$store.state.cdn + footerData.public_confidential" target="_blank">{{ $t("other.agree2") }}</a>
                        {{ $t("other.agree3") }}
                      </h5>
                    </div>
                  </div>
                </div>
                <button class="f-18 btn_blue" type="submit">
                  {{ $t("button.sendReport") }}
                </button>
              </form>
            </div>
          </div>
          <div class="consultation__items-row-col2">
            <div
              v-if="homeData?.image"
              class="consultation__items-row-col2-img"
            >
              <img :src="$store.state.cdn + homeData?.image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@/plugins/i18n-validators";
import axios from "axios";
import { useToast } from "vue-toastification";
import { sameAs } from "@vuelidate/validators";
export default {
  props: ["homeData"],
  setup: () => ({
    v$: useVuelidate(),
    toast: useToast(),
  }),
  data: () => ({
    userData: {
      phoneNumber: "",
      name: "",
      agree: false,
      footerData: null,
      lang: "ru",
    },
  }),
  validations() {
    return {
      userData: {
        phoneNumber: {
          required,
          minLength: minLength(18),
        },
        name: {
          required,
        },
        agree: {
          sameAs: sameAs(true),
        },
      },
    };
  },
  created() {
    this.FETCH_FOOTER();
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
  methods: {
    SUMBIT() {
      this.v$.userData.$touch();
      if (!this.v$.userData.$invalid) {
        const article = {
          phone_number: this.userData.phoneNumber,
          user_name: this.userData.name,
          agree: this.userData.agree,
        };
        axios
          .post("feedback", article)
          .then((response) => {
            if (response.status === 200) {
              this.toast.success(this.$t("alert"));
              (this.userData.name = ""), (this.userData.phoneNumber = "");
              this.v$.$reset();
            } else {
              console.log(response);
            }
          })
          .catch((e) => {
            this.toast.error(e.response.data?.message);
          });
      }
    },
    FETCH_FOOTER() {
      axios
        .get("footer")
        .then((response) => {
          if (response.status === 200) {
            this.footerData = response.data;
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          this.LOADER();
          alert(e.response.data?.message);
        });
    },
  },
};
</script>

<style></style>
