import { createStore } from 'vuex'

export default createStore({
  state: {
    cdn: 'https://admin.supersign.kz',
    // cdn: 'http://localhost:3333'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
