<template>
  <div class="header">
    <div class="header__content container">
      <div class="header__items">
        <div class="header__items-logo">
          <img src="@/assets/images/svg/Logo.svg" alt="" />
        </div>
        <div class="header__items-lang">
          <button @click="$router.push({ name: 'Live' })" class="btn_blue f-14">
            LIVE<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.31585 4.83892C7.53281 3.72836 6.23937 3.02912 4.70309 2.91292L4.74494 2.3617C6.42509 2.48879 7.87825 3.25882 8.76772 4.52031C9.65719 5.78181 9.89442 7.40917 9.44981 9.03441L8.91656 8.8887C9.3231 7.40263 9.09889 5.94948 8.31585 4.83892Z" fill="white"/>
<path d="M6.43089 6.1681C5.9266 5.45288 5.09093 4.99852 4.09072 4.92286L4.13257 4.37165C5.27665 4.45819 6.27204 4.98334 6.88276 5.84949C7.49348 6.71564 7.6538 7.82959 7.35105 8.93627L6.8178 8.79056C7.08248 7.82304 6.93518 6.88331 6.43089 6.1681Z" fill="white"/>
<path d="M3.99058 6.8742C4.36492 6.61025 4.88228 6.69966 5.14615 7.07388C5.41001 7.44811 5.32045 7.96545 4.94611 8.22939C4.57177 8.49334 4.05441 8.40393 3.79054 8.02971C3.52668 7.65548 3.61624 7.13814 3.99058 6.8742Z" fill="white"/>
<path d="M8.31585 4.83892C7.53281 3.72836 6.23937 3.02912 4.70309 2.91292L4.74494 2.3617C6.42509 2.48879 7.87825 3.25882 8.76772 4.52031C9.65719 5.78181 9.89442 7.40917 9.44981 9.03441L8.91656 8.8887C9.3231 7.40263 9.09889 5.94948 8.31585 4.83892Z" stroke="white" stroke-width="0.5"/>
<path d="M6.43089 6.1681C5.9266 5.45288 5.09093 4.99852 4.09072 4.92286L4.13257 4.37165C5.27665 4.45819 6.27204 4.98334 6.88276 5.84949C7.49348 6.71564 7.6538 7.82959 7.35105 8.93627L6.8178 8.79056C7.08248 7.82304 6.93518 6.88331 6.43089 6.1681Z" stroke="white" stroke-width="0.5"/>
<path d="M3.99058 6.8742C4.36492 6.61025 4.88228 6.69966 5.14615 7.07388C5.41001 7.44811 5.32045 7.96545 4.94611 8.22939C4.57177 8.49334 4.05441 8.40393 3.79054 8.02971C3.52668 7.65548 3.61624 7.13814 3.99058 6.8742Z" stroke="white" stroke-width="0.5"/>
</svg>

          </button>
          <div class="header__items-lang-change">
            <button @click="showInfo(open[0], 0)">
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.7888 14C27.7888 21.1797 21.9605 27 14.7708 27M27.7888 14C27.7888 6.8203 21.9605 1 14.7708 1M27.7888 14H1.75269M14.7708 27C7.58108 27 1.75269 21.1797 1.75269 14M14.7708 27C17.1673 27 19.1101 21.1797 19.1101 14C19.1101 6.8203 17.1673 1 14.7708 1M14.7708 27C12.3742 27 10.4314 21.1797 10.4314 14C10.4314 6.8203 12.3742 1 14.7708 1M1.75269 14C1.75269 6.8203 7.58108 1 14.7708 1"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <dropdownVue
              class="header__items-lang-change-dropdown-list"
              :open="open[0]"
              :class="{ databody__active: open[0] }"
            >
              <div
                class="header__items-login-dropdown-list-all font-16"
                v-for="(link, idx) in langLinks"
                :key="idx"
              >
                <button @click.prevent="changeLang(link)">
                  <p class="f-18">{{ link.name }}</p>
                </button>
              </div>
            </dropdownVue>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdownVue from "@/components/dropdown";
export default {
  components: {
    dropdownVue,
  },
  data() {
    const lang = localStorage.getItem("lang") || "kz";
    return {
      navLinks: [
        {
          title: "О нас",
          route: "https://tayyab.kz/about",
        },
        {
          title: "Исламские карты",
          route: "https://tayyab.kz/cards",
        },
        {
          title: "Соответствие шариату",
          route: "https://tayyab.kz/shariyah",
        },
        {
          title: "Иман",
          route: "https://tayyab.kz/iman",
        },
        {
          title: "Статьи",
          route: "https://tayyab.kz/articles",
        },
        {
          title: "Tayyab Store",
          route: "https://tayyab.kz/store",
        },
        {
          title: "FAQ",
          route: "https://tayyab.kz/faq",
        },
        {
          title: "Акции",
          route: "https://tayyab.kz/specials",
        },
        {
          title: "Скидки",
          route: "https://tayyab.kz/sale",
        },
      ],
      selected: lang,
      langLinks: [
        {
          name: "Қаз",
          value: "kz",
        },
        {
          name: "Рус",
          value: "ru",
        },
      ],
      open: [false],
    };
  },
  computed: {
    selectedLang() {
      return this.langLinks.find((item) => item.value === this.selected);
    },
  },
  methods: {
    changeLang(value) {
      localStorage.setItem("lang", value.value);
      window.location.reload();
    },
    showInfo(value, index) {
      value = !value;
      this.open[index] = value;
      this.$nextTick(this.$forceUpdate);
    },
  },
};
</script>

<style></style>
