<template>
  <div>
    <Header />
    <div class="home">
      <Banner @scroll="scrollTo" :homeData="homeData?.banner" />
      <About :homeData="homeData?.about" />
      <!-- <Translation /> -->
      <Photogallery :homeData="homeData?.gallery" />
      <SwiperAbout :homeData="homeData?.myCompanies" />
      <Consultation :homeData="homeData?.consultation" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Banner from "@/components/home/Banner";
import Translation from "@/components/home/translation";
import About from "@/components/home/AboutCompany";
import Photogallery from "@/components/home/Photogallery.vue";
import SwiperAbout from "@/components/home/SwiperAbout.vue";
import Consultation from "@/components/home/Consultation.vue";
import Header from "@/components/layouts/Header";
import Footer from "@/components/layouts/Footer";
import axios from "axios";
import { defineComponent, computed, reactive } from "vue"
import { useHead } from "@vueuse/head"

export default {

  components: {
    Banner,
    Translation,
    About,
    Photogallery,
    SwiperAbout,
    Consultation,
    Header,
    Footer,
  },
  name: "HomeView",
  setup() {
    const siteData = reactive({
      title:  'Supersign',
      meta_title: 'Наружная реклама на светодиодном экране в Алматы.',
      meta_description:  'Медиаэкран SmartVision ADK — один из крупнейших рекламных медиаэкранов в городе Алматы. Светодиодный экран расположен на пересечении улиц Сатпаева и Тлендиева, перед главным въездом в ТРК “ADK”. Угловое расположение экрана позволяет добиться эффекта глубины (3D) в рекламных роликах и привлекает взгляды прохожих и водителей',
      meta_keywords: 'Реклама, Наружная реклама, Реклама на светодиодных экрана, Реклама в Алматы, LED реклама 19:02',
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `title`,
          content: computed(() => siteData.meta_title),
        },
      ],
      description: computed(() => siteData.meta_description),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.meta_description),
        },
      ],
      meta: [
        {
          name: `keywords`,
          content: computed(() => siteData.meta_keywords),
        },
      ],
    })
  },
  
  data: () => ({
    homeData: null,
  }),
  created() {
    this.FETCH_HOME()
  },
  methods: {
    FETCH_HOME() {
      axios
        .get("homepages")
        .then((response) => {
          if (response.status === 200) {
           this.homeData = response.data
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          alert(e.response.data?.message);
        });
    },
    scrollTo(link) {
      const offsetTop = document.getElementById(link);
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>
