<template>
  <div class="aboutCompany">
    <div class="aboutCompany__content container">
      <div v-if="homeData" class="aboutCompany__items">
        <div
          data-aos="fade-down-right"
          data-aos-duration="2000"
          class="aboutCompany__items-title"
        >
          <span class="f-40"
            >{{ $t("other.companyTitle") }}</span
          >
        </div>
        <div class="aboutCompany__items-list">
          <swiper
            :slidesPerView="4"
            :breakpoints="breakpoints"
            :modules="modules"
            :navigation="navigation"
            class="aboutCompany__items-list-swiper"
          >
            <swiper-slide
              class="aboutCompany__items-list-swiper-swiperSlide"
              v-for="(item,idx) in homeData"
              :key="'item'+idx"
            >
              <img :src="$store.state.cdn + item.image1" />
            </swiper-slide>
          </swiper>
          <div class="aboutCompany__items-list__navigation">
            <div class="swiper-button-next selectornx3"></div>
            <div class="swiper-button-prev selectorpr3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Grid, Navigation } from "swiper";
import "swiper/css/grid";
SwiperCore.use([Navigation, Grid]);
export default {
  props: ['homeData'],
  components: { Swiper, SwiperSlide },
  data: () => ({
    navigation: {
      nextEl: ".selectornx3",
      prevEl: ".selectorpr3",
    },
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
      },
      512: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  }),
};
</script>

<style></style>
