<template>
  <div v-if="transformedArray" class="photogallery">
    <div class="photogallery__content container">
      <div class="photogallery__items">
        <div
          data-aos="fade-down-right"
          data-aos-duration="2000"
          class="photogallery__items-title"
        >
          <h1 class="f-40">Фотогалерея</h1>
        </div>
        <div class="photogallery__items-images">
          <div
          class="photogallery__items-images-row"
            v-for="(row, rowIndex) in checkSlice ? transformedArray.slice(0,1) : transformedArray"
            :key="'rowIndex' + rowIndex"
          >
            <div v-for="(col, colIndex) in row" :key="'colIndex' + colIndex" class="photogallery__items-images-row-col">
              <div v-for="(img, imgIndex) in col" :key="'imgIndex' + imgIndex" class="photogallery__items-images-row-col-img">
                <img :src="$store.state.cdn +  img.image1" alt="Image" />
              </div>
            </div>
          </div>
          <!-- <img src="@/assets/images/png/Frame8.png" alt="" /> -->
        </div>
        <div v-if="homeData.length > 3" class="photogallery__items-button">
          <button @click="checkSlice = !checkSlice" class="f-18">{{ checkSlice ? $t("button.sendRequest") : $t("button.sendRequestNone")  }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['homeData'],
  data: () =>({
    transformedArray: null,
    checkSlice: true,
  }),
  methods: {
    imageNewRows() {
      let inputArray = this.homeData
      const transformedArray = [];
      let i = 0;
      while (i < inputArray.length) {
        const temp = [];
        temp.push([inputArray[i]]);
        i += 1;

        if (i + 1 < inputArray.length) {
          temp.push([inputArray[i], inputArray[i + 1]]);
          i += 2;
        }
        console.log(temp);
        transformedArray.push(temp);
      }
      this.transformedArray =  transformedArray;
    },
  },
  watch: {
    homeData(){
      this.imageNewRows()
    }
  }
};
</script>

<style></style>
