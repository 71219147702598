<template>
  <div class="pLayout">
    <div class="pLayout__content">
      <div class="pLayout__items">
        <div class="pLayout__items-navs">
          <div class="pLayout__items-navs-tab">
            <ul>
              <li v-for="(item, idx) in menu" :key="idx">
                <router-link
                  :class="$route.name === item.link ? 'activeLinks' : ''"
                  class="f-16"
                  :to="{ name: item.link }"
                >
                  <img :src="item.icon" alt="" />
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="pLayout__items-page">
          <div class="pLayout__items-page-footer">
            <div></div>
            <div>
              <span
                ><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7 7.49991V3H8V7.29289L10.8536 10.1464L10.1464 10.8536L7.14645 7.85355C7.04882 7.75592 7 7.62787 7 7.49991Z" fill="white"/>
</svg>

              </span>
              <p class="f-14">{{ currentTime }}</p>
            </div>
            <div>
              <span
                ><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0.5C3.08579 0.5 2.75 0.835786 2.75 1.25V2H2C1.17157 2 0.5 2.67157 0.5 3.5V11C0.5 11.8284 1.17157 12.5 2 12.5H11C11.8284 12.5 12.5 11.8284 12.5 11V3.5C12.5 2.67157 11.8284 2 11 2H10.25V1.25C10.25 0.835786 9.91421 0.5 9.5 0.5C9.08579 0.5 8.75 0.835786 8.75 1.25V2H4.25V1.25C4.25 0.835786 3.91421 0.5 3.5 0.5ZM3.5 4.25C3.08579 4.25 2.75 4.58579 2.75 5C2.75 5.41421 3.08579 5.75 3.5 5.75H9.5C9.91421 5.75 10.25 5.41421 10.25 5C10.25 4.58579 9.91421 4.25 9.5 4.25H3.5Z" fill="white"/>
</svg>

              </span>
              <p class="f-14">{{ currentDate }}</p>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalLayout",
  data: () => ({
    menu: [
      {
        id: 0,
        link: "home",
        icon: require("@/assets/images/svg/pP1.svg"),
      },
      {
        id: 1,
        link: "Live",
        icon: require("@/assets/images/svg/pP2.svg"),
      },
      {
        id: 2,
        link: "Report",
        icon: require("@/assets/images/svg/pP3.svg"),
      },
      // {
      //   id: 3,
      //   link: "home",
      //   icon: require("@/assets/images/svg/pP4.svg"),
      // },
    ],
    currentDate: null,
    currentTime: null,
  }),
  created() {
    let newDate = new Date();
    this.currentDate = this.formatDate(newDate)
    this.currentTime = this.formatTime(newDate)
  },
  computed: {},
  methods: {

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(date){
      const hoursT = String(date.getHours()).padStart(2, "0");
      const minutesT = String(date.getMinutes()).padStart(2, "0");
      return `${hoursT}:${minutesT}`;
    }
  },
};
</script>

<style scoped lang="scss"></style>
