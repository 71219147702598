<template>
  <div class="translation">
    <div class="translation__content">
      <div class="translation__items">
        <iframe
        scrolling="no"
          style="width: 100%; aspect-ratio: 16/9;"
          src="https://admin.supersign.kz/live"
          frameborder="0"
        ></iframe>
       <!-- <video ref="videoElement" controls></video> -->
        <!-- <video ref="videoPlayer" autoplay controls></video> -->
        <!-- <img style="width: 100%;" src="@/assets/images/png/video.png" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    
  },
  methods: {
  },
};
</script>

<style>
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
