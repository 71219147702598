<template>
  <div class="banner">

    <!-- <video ref="videoPlayer" autoplay muted loop playsinline id="vid">
      <source :src="require('@/assets/buildboard.mp4')" type="video/mp4" />
    </video> -->
    
    <div id="vid"  style="position: relative; padding-top: 56.25%; width: 100%;height: 100%"><iframe :src="homeData?.url" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" frameborder="0" allowfullscreen style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"></iframe></div>
    <!-- <div class="playPause">
      <button v-if="pause" @click="playVideo">
        <img src="@/assets/images/svg/play.svg" />
      </button>
      <button v-else @click="pauseVideo">
        <img src="@/assets/images/svg/pause.svg" />
      </button>
    </div> -->
    <!-- <button class="f-25 banner__sound" @click="soundSwitch(event)">
      SOUND ON
    </button> -->
    <div class="container">
      <div class="banner__content">
        <div v-if="homeData" class="banner__items">
          <div
            data-aos="fade-down-right"
            data-aos-duration="2000"
            class="banner__items-title"
          >
            <span class="f-61" v-html="homeData.title"></span>
          </div>
          <div
            data-aos="fade-down-left"
            data-aos-duration="2000"
            class="banner__items-btn"
          >
            <button
              @click.prevent="scrollToSection('consultation')"
              class="btn_blue f-14"
            >
              {{ $t("button.ostavZayavka") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["homeData"],
  data: () => ({
    pause: false,
  }),
  computed: {
    videoOnPause() {
      let video = this.$refs.videoPlayer;
      if (video.paused) {
        console.log("1");
        return true;
      } else {
        console.log("2");
        return false;
      }
    },
  },
  methods: {
    soundSwitch() {
      var vid = document.querySelector("#vid");
      vid.muted = !vid.muted;
      if (vid.muted) {
        event.target.innerHTML = "SOUND ON";
      } else {
        event.target.innerHTML = "SOUND OFF";
      }
      console.log(event.target);
    },
    playVideo() {
      var vid = document.querySelector("#vid");
      vid.play();
      this.checkVideoState();
    },
    pauseVideo() {
      var vid = document.querySelector("#vid");
      vid.pause();
      this.checkVideoState();
    },
    checkVideoState() {
      const video = this.$refs.videoPlayer;

      if (video.paused) {
        console.log("Video is paused");
        this.pause = true;
      } else if (video.ended) {
        console.log("Video has ended");
      } else {
        console.log("Video is playing");
        this.pause = false;
      }
    },
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
  },
};
</script>

<style></style>
